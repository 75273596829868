.attach-item {
  height: 56px;
  display: flex;
  align-items: center;
}
.progressLine > div > div > div > div{
  background-color: #323D47;
  border-radius: 0;
}
.attach-name {
  font-size: 14px;
  color: rgba(50,61,71,.4);
  white-space: nowrap;
  display: block;
  text-overflow: ellipsis;
  overflow: hidden;
  width: calc(100% - 40px);
}
.attach-thumb {
  width: 72px;
  justify-content: center;
  display: flex;
  align-items: center;
}
.attach-atachmentDetail {
  display: flex;
  width: 100%;
  height: 100%;
  border-bottom: 1px solid rgba(50,61,71,.16);
  position: relative;
  align-items: center;
}
.attach-progress {
  position: absolute;
  left: 0;
  right: 0;
  bottom: -8px;
}
.attach-remove {
  margin-right: 16px
}
@import url(/node_modules/draft-js/dist/Draft.css);
.nst-ico {
  color: transparent;
  background-position: center center;
  background-size: cover;
  -webkit-transform-origin: center;
          transform-origin: center;
  -webkit-transition: none;
  transition: none;
  box-sizing: border-box;
  background-repeat: no-repeat; }
  .nst-ico:hover, .nst-ico:focus {
    color: transparent;
    background-color: transparent;
    box-sizing: border-box;
    border: none;
    background-position: center center;
    background-size: cover; }

.ic_absolout_place_solid_24,
.ic_absolout_place_solid_24:hover,
.ic_absolout_place_solid_24:focus {
  background-image: url("/images/ic_absolout_place_solid_24.png"); }

.ic_access_sensewire_24,
.ic_access_sensewire_24:hover,
.ic_access_sensewire_24:focus {
  background-image: url("/images/ic_access_sensewire_24.png"); }

.ic_access_solid_24,
.ic_access_solid_24:hover,
.ic_access_solid_24:focus {
  background-image: url("/images/ic_access_solid_24.png"); }

.ic_account_sensewire_24,
.ic_account_sensewire_24:hover,
.ic_account_sensewire_24:focus {
  background-image: url("/images/ic_account_sensewire_24.png"); }

.ic_account_solid_24,
.ic_account_solid_24:hover,
.ic_account_solid_24:focus {
  background-image: url("/images/ic_account_solid_24.png"); }

.ic_admins_log_solid_24,
.ic_admins_log_solid_24:hover,
.ic_admins_log_solid_24:focus {
  background-image: url("/images/ic_admins_log_solid_24.png"); }

.ic_bell_solid_24,
.ic_bell_solid_24:hover,
.ic_bell_solid_24:focus {
  background-image: url("/images/ic_bell_solid_24.png"); }

.ic_bin_force_16,
.ic_bin_force_16:hover,
.ic_bin_force_16:focus {
  background-image: url("/images/ic_bin_force_16.png"); }

.ic_brick_wall_solid_16,
.ic_brick_wall_solid_16:hover,
.ic_brick_wall_solid_16:focus {
  background-image: url("/images/ic_brick_wall_solid_16.png"); }

.ic_brick_wall_solid_24,
.ic_brick_wall_solid_24:hover,
.ic_brick_wall_solid_24:focus {
  background-image: url("/images/ic_brick_wall_solid_24.png"); }

.ic_check_senesewire_24,
.ic_check_senesewire_24:hover,
.ic_check_senesewire_24:focus {
  background-image: url("/images/ic_check_senesewire_24.png"); }

.ic_crown_shine_24,
.ic_crown_shine_24:hover,
.ic_crown_shine_24:focus {
  background-image: url("/images/ic_crown_shine_24.png"); }

.ic_dashboard_sensewire_24,
.ic_dashboard_sensewire_24:hover,
.ic_dashboard_sensewire_24:focus {
  background-image: url("/images/ic_dashboard_sensewire_24.png"); }

.ic_dashboard_solid_24,
.ic_dashboard_solid_24:hover,
.ic_dashboard_solid_24:focus {
  background-image: url("/images/ic_dashboard_solid_24.png"); }

.ic_earth_solid_16,
.ic_earth_solid_16:hover,
.ic_earth_solid_16:focus {
  background-image: url("/images/ic_earth_solid_16.png"); }

.ic_earth_solid_24,
.ic_earth_solid_24:hover,
.ic_earth_solid_24:focus {
  background-image: url("/images/ic_earth_solid_24.png"); }

.ic_gear_sensewire_24,
.ic_gear_sensewire_24:hover,
.ic_gear_sensewire_24:focus {
  background-image: url("/images/ic_gear_sensewire_24.png"); }

.ic_gear_solid_-1,
.ic_gear_solid_-1:hover,
.ic_gear_solid_-1:focus {
  background-image: url("/images/ic_gear_solid_-1.png"); }

.ic_gear_solid_24,
.ic_gear_solid_24:hover,
.ic_gear_solid_24:focus {
  background-image: url("/images/ic_gear_solid_24.png"); }

.ic_grandplace_solid_-1,
.ic_grandplace_solid_-1:hover,
.ic_grandplace_solid_-1:focus {
  background-image: url("/images/ic_grandplace_solid_-1.png"); }

.ic_grandplace_solid_16,
.ic_grandplace_solid_16:hover,
.ic_grandplace_solid_16:focus {
  background-image: url("/images/ic_grandplace_solid_16.png"); }

.ic_heavy_arrow_down_solid_16,
.ic_heavy_arrow_down_solid_16:hover,
.ic_heavy_arrow_down_solid_16:focus {
  background-image: url("/images/ic_heavy_arrow_down_solid_16.png"); }

.ic_heavy_arrow_down_solid_24,
.ic_heavy_arrow_down_solid_24:hover,
.ic_heavy_arrow_down_solid_24:focus {
  background-image: url("/images/ic_heavy_arrow_down_solid_24.png"); }

.ic_manager-and-member_solid_24,
.ic_manager-and-member_solid_24:hover,
.ic_manager-and-member_solid_24:focus {
  background-image: url("/images/ic_manager-and-member_solid_24.png"); }

.ic_lock_solid_24,
.ic_lock_solid_24:hover,
.ic_lock_solid_24:focus {
  background-image: url("/images/ic_lock_solid_24.png"); }

.ic_logout_solid_24,
.ic_logout_solid_24:hover,
.ic_logout_solid_24:focus {
  background-image: url("/images/ic_logout_solid_24.png"); }

.ic_magnify_solid_24,
.ic_magnify_solid_24:hover,
.ic_magnify_solid_24:focus {
  background-image: url("/images/ic_magnify_solid_24.png"); }

.ic_manager_and_member_solid_16,
.ic_manager_and_member_solid_16:hover,
.ic_manager_and_member_solid_16:focus {
  background-image: url("/images/ic_manager_and_member_solid_16.png"); }

.ic_manager_solid_16,
.ic_manager_solid_16:hover,
.ic_manager_solid_16:focus {
  background-image: url("/images/ic_manager_solid_16.png"); }

.ic_manager_solid_24,
.ic_manager_solid_24:hover,
.ic_manager_solid_24:focus {
  background-image: url("/images/ic_manager_solid_24.png"); }

.ic_manager-and-member_solid_24,
.ic_manager-and-member_solid_24:hover,
.ic_manager-and-member_solid_24:focus {
  background-image: url("/images/ic_manager-and-member_solid_24.png"); }

.ic_member_solid_24,
.ic_member_solid_24:hover,
.ic_member_solid_24:focus {
  background-image: url("/images/ic_member_solid_24.png"); }

.ic_more_solid_16,
.ic_more_solid_16:hover,
.ic_more_solid_16:focus {
  background-image: url("/images/ic_more_solid_16.png"); }

.ic_more_solid_24,
.ic_more_solid_24:hover,
.ic_more_solid_24:focus {
  background-image: url("/images/ic_more_solid_24.png"); }

.ic_non_search_24,
.ic_non_search_24:hover,
.ic_non_search_24:focus {
  background-image: url("/images/ic_non_search_24.png"); }

.ic_open_message_solid_24,
.ic_open_message_solid_24:hover,
.ic_open_message_solid_24:focus {
  background-image: url("/images/ic_open_message_solid_24.png"); }

.ic_pencil_solid_16,
.ic_pencil_solid_16:hover,
.ic_pencil_solid_16:focus {
  background-image: url("/images/ic_pencil_solid_16.png"); }

.ic_places_sensewire_24,
.ic_places_sensewire_24:hover,
.ic_places_sensewire_24:focus {
  background-image: url("/images/ic_places_sensewire_24.png"); }

.ic_places_solid_24,
.ic_places_solid_24:hover,
.ic_places_solid_24:focus {
  background-image: url("/images/ic_places_solid_24.png"); }

.ic_plus_sensewire_16,
.ic_plus_sensewire_16:hover,
.ic_plus_sensewire_16:focus {
  background-image: url("/images/ic_plus_sensewire_16.png"); }

.ic_plus_solid_24,
.ic_plus_solid_24:hover,
.ic_plus_solid_24:focus {
  background-image: url("/images/ic_plus_solid_24.png"); }

.ic_relative_place_solid_24,
.ic_relative_place_solid_24:hover,
.ic_relative_place_solid_24:focus {
  background-image: url("/images/ic_relative_place_solid_24.png"); }

.ic_search_24,
.ic_search_24:hover,
.ic_search_24:focus {
  background-image: url("/images/ic_search_24.png"); }

.ic_search_solid_16,
.ic_search_solid_16:hover,
.ic_search_solid_16:focus {
  background-image: url("/images/ic_search_solid_16.png"); }

.ic_storage_sensewire_24,
.ic_storage_sensewire_24:hover,
.ic_storage_sensewire_24:focus {
  background-image: url("/images/ic_storage_sensewire_24.png"); }

.ic_storage_solid_24,
.ic_storage_solid_24:hover,
.ic_storage_solid_24:focus {
  background-image: url("/images/ic_storage_solid_24.png"); }

.ic_team_solid_16,
.ic_team_solid_16:hover,
.ic_team_solid_16:focus {
  background-image: url("/images/ic_team_solid_16.png"); }

.ic_team_solid_24,
.ic_team_solid_24:hover,
.ic_team_solid_24:focus {
  background-image: url("/images/ic_team_solid_24.png"); }

.ic_window_solid_16,
.ic_window_solid_16:hover,
.ic_window_solid_16:focus {
  background-image: url("/images/ic_window_solid_16.png"); }

.ic_window_solid_24,
.ic_window_solid_24:hover,
.ic_window_solid_24:focus {
  background-image: url("/images/ic_window_solid_24.png"); }

.ic_xcross_solid_24,
.ic_xcross_solid_24:hover,
.ic_xcross_solid_24:focus {
  background-image: url("/images/ic_xcross_solid_24.png"); }

.img_tickets,
.img_tickets:hover,
.img_tickets:focus {
  background-image: url("/images/img_tickets.png"); }

@font-face {
  font-family: 'YekanBakh';
  font-weight: 300;
  src: url("/fonts/YekanBakh/YekanBakhNestedWeb-Light.eot?") format("eot"), url("/fonts/YekanBakh/YekanBakhNestedWeb-Light.ttf") format("truetype"), url("/fonts/YekanBakh/YekanBakhNestedWeb-Light.woff") format("woff"), url("/fonts/YekanBakh/YekanBakhNestedWeb-Light.woff2") format("woff2"); }

@font-face {
  font-family: 'YekanBakh';
  font-weight: 400;
  src: url("/fonts/YekanBakh/YekanBakhNestedWeb-Regular.eot?") format("eot"), url("/fonts/YekanBakh/YekanBakhNestedWeb-Regular.ttf") format("truetype"), url("/fonts/YekanBakh/YekanBakhNestedWeb-Regular.woff") format("woff"), url("/fonts/YekanBakh/YekanBakhNestedWeb-Regular.woff2") format("woff2"); }

@font-face {
  font-family: 'YekanBakh';
  font-weight: 700;
  src: url("/fonts/YekanBakh/YekanBakhNestedWeb-Bold.eot?") format("eot"), url("/fonts/YekanBakh/YekanBakhNestedWeb-Bold.ttf") format("truetype"), url("/fonts/YekanBakh/YekanBakhNestedWeb-Bold.woff") format("woff"), url("/fonts/YekanBakh/YekanBakhNestedWeb-Bold.woff2") format("woff2"); }

:root {
  --force: #f34; }

html body {
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'YekanBakh', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace; }

.red-svg {
  fill: #f34;
  fill: var(--force); }

a, .link {
  color: #00B45A;
  background: transparent;
  text-decoration: none;
  outline: none;
  cursor: pointer;
  -webkit-transition: color .3s ease;
  transition: color .3s ease; }
  a:focus, .link:focus {
    text-decoration: underline;
    -webkit-text-decoration-skip: ink;
            text-decoration-skip-ink: auto; }
  a:hover, .link:hover {
    color: #14D769; }
  a:active, .link:active {
    color: #14D769; }
  a:active, .link:active, a:hover, .link:hover {
    outline: 0;
    text-decoration: none; }
  a[disabled], .link[disabled] {
    color: rgba(0, 0, 0, 0.25);
    cursor: not-allowed;
    pointer-events: none; }

._difv {
  display: flex;
  flex-direction: column; }

._df {
  display: flex; }

._cp, .link {
  cursor: pointer; }

.filler {
  flex: 1 1;
  display: flex; }

button.button-margin {
  margin: 0 8px; }

.scene-head {
  display: flex;
  height: 48px;
  border-bottom: 1px solid rgba(50, 61, 71, 0.08);
  margin-bottom: 24px;
  align-items: center; }

.suggests {
  padding: 16px 0;
  max-height: 256px;
  overflow: auto; }
  .suggests li {
    display: flex;
    height: 56px;
    align-items: center; }
  .suggests .add-button {
    margin: 0 24px 0 8px;
    color: #00b45a;
    font-size: 13px;
    cursor: pointer; }

.modal-close {
  width: 40px;
  margin: 0 2px;
  height: 40px;
  display: flex;
  cursor: pointer;
  justify-content: center;
  align-items: center;
  -webkit-transition: opacity .3s;
  transition: opacity .3s;
  opacity: .64; }
  .modal-close:hover {
    opacity: 1; }

.selecteds {
  display: flex;
  flex-wrap: wrap;
  margin: 0;
  padding: 0; }
  .selecteds li {
    height: 32px;
    background-color: #323d47;
    border-radius: 6px;
    display: flex;
    align-items: center;
    margin-top: 4px;
    margin-right: 4px;
    padding: 0 6px 0 4px; }
    .selecteds li span {
      color: white !important;
      font-size: 13px !important;
      padding-left: 4px !important; }

h2.page-head {
  margin: 0;
  display: flex;
  flex: 1 1;
  font-size: 18px;
  font-weight: bold;
  line-height: 56px;
  color: #323d47; }

.card-head {
  height: 48px;
  line-height: 48px;
  background: #fff;
  border-bottom: 1px solid #e9e9e9;
  padding: 0 24px;
  width: 100%;
  color: rgba(0, 0, 0, 0.85);
  padding: 0 24px;
  align-items: center;
  display: flex; }

.card-head h2 {
  flex: 1 1;
  margin: 0;
  display: flex;
  font-weight: normal;
  font-size: 14px; }

.card-head a, .card-head .link {
  justify-content: center;
  display: flex;
  height: 36px;
  align-items: center;
  display: flex;
  width: 36px; }

.reloading {
  background-color: rgba(50, 61, 71, 0.16);
  text-align: center;
  border-radius: 4px;
  font-weight: 700; }

.card-body {
  padding: 24px;
  flex-direction: column;
  display: flex; }

.online-users-card {
  overflow: auto;
  height: 100%; }
  .online-users-card ul {
    list-style: none;
    display: flex;
    padding: 0;
    margin: 0;
    flex: none;
    flex-direction: column; }
    .online-users-card ul li {
      display: flex;
      font-size: 14px; }
      .online-users-card ul li:first-of-type {
        margin-bottom: 4px;
        font-weight: bold; }

.multi-chart-title {
  cursor: pointer; }
  .multi-chart-title.deactive {
    text-decoration: line-through; }

.multi-chart-title:after {
  content: '/';
  text-decoration: unset !important;
  opacity: 0.7;
  margin: 0 2px; }

.multi-chart-title:last-of-type:after {
  content: ''; }

.active-user {
  fill: #00B45A; }
  .active-user i {
    margin-right: 7px;
    -webkit-transform: translateY(3px);
            transform: translateY(3px); }

.deactivee-user {
  fill: rgba(50, 61, 71, 0.08); }
  .deactivee-user i {
    margin-right: 7px; }

.nst-modal .MuiDialogTitle-root {
  padding: 0; }

.place-avatar-border::after {
  left: 0;
  position: absolute;
  width: 32px;
  height: 32px;
  border-radius: 3px;
  box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0.1);
  pointer-events: none;
  content: "";
  top: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%); }

.chart-card, .card-body, .MuiPaper-root {
  position: relative; }

.nst-modal {
  width: 100%; }

.nst-opacity-hover-parent {
  border-radius: 5px; }
  .nst-opacity-hover-parent .nst-opacity-hover {
    opacity: 0;
    cursor: pointer; }
    .nst-opacity-hover-parent .nst-opacity-hover.no-hover {
      opacity: 1; }
  .nst-opacity-hover-parent:hover {
    background-color: rgba(50, 61, 71, 0.04); }
    .nst-opacity-hover-parent:hover .nst-opacity-hover {
      opacity: 1; }

.user-in-place-item {
  position: relative; }
  .user-in-place-item .promote,
  .user-in-place-item .remove {
    position: absolute;
    opacity: 0;
    -webkit-transition: opacity .3s;
    transition: opacity .3s;
    background-color: white;
    border-radius: 6px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 32px;
    height: 100%;
    top: 0; }
  .user-in-place-item:hover .promote,
  .user-in-place-item:hover .remove {
    opacity: 1; }
  .user-in-place-item .promote {
    right: 32px; }
  .user-in-place-item .remove {
    right: 4px;
    fill: #f34;
    fill: var(--force); }

.place-members ul {
  margin: 0;
  padding: 0; }

.place-members h5 {
  color: rgba(50, 61, 71, 0.64);
  line-height: 42px;
  padding: 0 12px;
  font-size: 14px; }

.place-members li {
  height: 42px;
  display: flex;
  align-items: center; }
  .place-members li .ant-row-flex {
    flex: 1 1; }
  .place-members li .ImageHolder-avatar-24 {
    margin: 0 12px; }
  .place-members li .UserAvatar--inner {
    text-align: left !important;
    justify-content: flex-start !important; }
  .place-members li span {
    padding: 0 !important;
    max-width: 100% !important; }
  .place-members li .uname.UserAvatar {
    flex: 1 1;
    max-width: calc(100% - 120px); }
  .place-members li i {
    margin: 0 8px; }

.place-members .addMemberItem {
  opacity: .4;
  cursor: pointer; }
  .place-members .addMemberItem:hover {
    opacity: 1; }
  .place-members .addMemberItem i {
    margin: 0 16px; }

.PlaveView-detail {
  display: flex;
  flex-direction: column;
  justify-content: center; }

.ImageHolder-place:after {
  left: 0;
  position: absolute;
  top: 0;
  width: 32px;
  height: 32px;
  border-radius: 3px;
  box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0.1);
  pointer-events: none;
  content: "";
  top: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%); }

.ImageHolder-avatar:after {
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  border-radius: 100%;
  box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0.1);
  pointer-events: none;
  content: "";
  top: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%); }

.ImageHolder-avatar-64:after {
  left: 0;
  position: absolute;
  top: 0;
  width: 64px;
  height: 64px;
  border-radius: 100%;
  box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0.1);
  pointer-events: none;
  content: "";
  top: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%); }

.ImageHolder-avatar-24:after {
  left: 0;
  position: absolute;
  top: 0;
  width: 24px;
  height: 24px;
  border-radius: 100%;
  box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0.1);
  pointer-events: none;
  content: "";
  top: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%); }

.ImageHolder-avatar-20:after {
  left: 0;
  position: absolute;
  top: 0;
  width: 20px;
  height: 20px;
  border-radius: 100%;
  box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0.1);
  pointer-events: none;
  content: "";
  top: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%); }

.ImageHolder-avatar-16:after {
  left: 0;
  position: absolute;
  top: 0;
  width: 16px;
  height: 16px;
  border-radius: 100%;
  box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0.1);
  pointer-events: none;
  content: "";
  top: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%); }

.attach-item {
  height: 56px;
  display: flex;
  align-items: center;
}
.progressLine > div > div > div > div{
  background-color: #323D47;
  border-radius: 0;
}
.attach-name {
  font-size: 14px;
  color: rgba(50,61,71,.4);
  white-space: nowrap;
  display: block;
  text-overflow: ellipsis;
  overflow: hidden;
  width: calc(100% - 40px);
}
.attach-thumb {
  width: 72px;
  justify-content: center;
  display: flex;
  align-items: center;
}
.attach-atachmentDetail {
  display: flex;
  width: 100%;
  height: 100%;
  border-bottom: 1px solid rgba(50,61,71,.16);
  position: relative;
  align-items: center;
}
.attach-progress {
  position: absolute;
  left: 0;
  right: 0;
  bottom: -8px;
}
.attach-remove {
  margin-right: 16px
}
.fileBadge {
  display: inline-block;
  border: 1px solid #000;
  border-radius: 3px;
  width: 40px;
}

.filesTypesImages {
  margin: 0 0 0 13px;
  position: relative;
}

.imgContainer:after {
  left: 0;
  top: 0;
  position: absolute;
  width: 40px;
  height: 40px;
  box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0.1);
  pointer-events: none;
  content: "";
  border-radius: 3px;
}

.fileBadge {
  text-align: center;
  text-transform: uppercase;
  height: 28px;
  line-height: 20px;
  padding: 4px;
  text-overflow: ellipsis;
  overflow: hidden;
  color: #000;
  background: #fff;
  font-weight: bold;
  font-size: 10px;
}

.fileBadgeArchive {
  border-color: #5A28BE;
  color: #5A28BE;
  margin-top: 8px;
  font-weight: bold;
}

.fileBadgePdf {
  border-color: #FA0032;
  color: #FA0032;
  font-weight: bold;
}

.fileBadgeDoc {
  border-color: #148CF0;
  color: #148CF0;
  font-weight: bold;
}

.fileBadgeAud, .fileBadgeVid, .fileBadgeImg {
  border-color: #3CC882;
  color: #3CC882;
  font-weight: bold;
}

.imageContainer {
  display: flex;
  align-items: center;
  padding: 0 16px 0 0;
}

.AttachmentList {
  /*transform: translateY(100%);*/
  background-color: white;
  flex-direction: column;
  border-top: 1px solid rgba(50,61,71,.16);
}
.AttachmentListTop {
  height: 48px;
  width: 100%;
  position: relative;
  display: flex;
  align-items: center;
}
.AttachmentListTop span{
  width: 100%;
  padding-left: 16px;
  font-size: 14px;
  line-height: 19px;
  color: rgba(50,61,71,.72);
}
.totallProgress {
  position: absolute;
  left: 0;
  top: -7px;
  right: 0;
}
.attachListAnchor {
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 8px;
}
.attachListAnchor i {
  -webkit-transform: rotateZ(180deg);
          transform: rotateZ(180deg);
}
.expanded i{
  -webkit-transform: rotateZ(0);
          transform: rotateZ(0);
}

.ReactCrop {
  position: relative;
  display: inline-block;
  cursor: crosshair;
  overflow: hidden;
  max-width: 100%;
  background-color: #000; }

.ReactCrop:focus {
  outline: none; }

.ReactCrop--disabled {
  cursor: inherit; }

.ReactCrop__image {
  display: block;
  max-width: 100%; }

.ReactCrop--crop-invisible .ReactCrop__image {
  opacity: 0.5; }

.ReactCrop__crop-selection {
  position: absolute;
  top: 0;
  left: 0;
  -webkit-transform: translate3d(0, 0, 0);
          transform: translate3d(0, 0, 0);
  box-sizing: border-box;
  cursor: move;
  box-shadow: 0 0 0 9999em rgba(0, 0, 0, 0.5);
  border: 1px solid;
  border-image-source: url("data:image/gif;base64,R0lGODlhCgAKAJECAAAAAP///////wAAACH/C05FVFNDQVBFMi4wAwEAAAAh/wtYTVAgRGF0YVhNUDw/eHBhY2tldCBiZWdpbj0i77u/IiBpZD0iVzVNME1wQ2VoaUh6cmVTek5UY3prYzlkIj8+IDx4OnhtcG1ldGEgeG1sbnM6eD0iYWRvYmU6bnM6bWV0YS8iIHg6eG1wdGs9IkFkb2JlIFhNUCBDb3JlIDUuMy1jMDExIDY2LjE0NTY2MSwgMjAxMi8wMi8wNi0xNDo1NjoyNyAgICAgICAgIj4gPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4gPHJkZjpEZXNjcmlwdGlvbiByZGY6YWJvdXQ9IiIgeG1sbnM6eG1wTU09Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC9tbS8iIHhtbG5zOnN0UmVmPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvc1R5cGUvUmVzb3VyY2VSZWYjIiB4bWxuczp4bXA9Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC8iIHhtcE1NOkRvY3VtZW50SUQ9InhtcC5kaWQ6OEI5RDc5MTFDNkE2MTFFM0JCMDZEODI2QTI4MzJBOTIiIHhtcE1NOkluc3RhbmNlSUQ9InhtcC5paWQ6OEI5RDc5MTBDNkE2MTFFM0JCMDZEODI2QTI4MzJBOTIiIHhtcDpDcmVhdG9yVG9vbD0iQWRvYmUgUGhvdG9zaG9wIENTNiAoTWFjaW50b3NoKSI+IDx4bXBNTTpEZXJpdmVkRnJvbSBzdFJlZjppbnN0YW5jZUlEPSJ4bXAuZGlkOjAyODAxMTc0MDcyMDY4MTE4MDgzQzNDMjA5MzREQ0ZDIiBzdFJlZjpkb2N1bWVudElEPSJ4bXAuZGlkOjAyODAxMTc0MDcyMDY4MTE4MDgzQzNDMjA5MzREQ0ZDIi8+IDwvcmRmOkRlc2NyaXB0aW9uPiA8L3JkZjpSREY+IDwveDp4bXBtZXRhPiA8P3hwYWNrZXQgZW5kPSJyIj8+Af/+/fz7+vn49/b19PPy8fDv7u3s6+rp6Ofm5eTj4uHg397d3Nva2djX1tXU09LR0M/OzczLysnIx8bFxMPCwcC/vr28u7q5uLe2tbSzsrGwr66trKuqqainpqWko6KhoJ+enZybmpmYl5aVlJOSkZCPjo2Mi4qJiIeGhYSDgoGAf359fHt6eXh3dnV0c3JxcG9ubWxramloZ2ZlZGNiYWBfXl1cW1pZWFdWVVRTUlFQT05NTEtKSUhHRkVEQ0JBQD8+PTw7Ojk4NzY1NDMyMTAvLi0sKyopKCcmJSQjIiEgHx4dHBsaGRgXFhUUExIREA8ODQwLCgkIBwYFBAMCAQAAIfkEBQoAAgAsAAAAAAoACgAAAhWEERkn7W3ei7KlagMWF/dKgYeyGAUAIfkEBQoAAgAsAAAAAAoACgAAAg+UYwLJ7RnQm7QmsCyVKhUAIfkEBQoAAgAsAAAAAAoACgAAAhCUYgLJHdiinNSAVfOEKoUCACH5BAUKAAIALAAAAAAKAAoAAAIRVISAdusPo3RAzYtjaMIaUQAAIfkEBQoAAgAsAAAAAAoACgAAAg+MDiem7Q8bSLFaG5il6xQAIfkEBQoAAgAsAAAAAAoACgAAAg+UYRLJ7QnQm7SmsCyVKhUAIfkEBQoAAgAsAAAAAAoACgAAAhCUYBLJDdiinNSEVfOEKoECACH5BAUKAAIALAAAAAAKAAoAAAIRFISBdusPo3RBzYsjaMIaUQAAOw==");
  border-image-slice: 1;
  border-image-repeat: repeat; }

.ReactCrop--disabled .ReactCrop__crop-selection {
  cursor: inherit; }

.ReactCrop__drag-handle {
  position: absolute;
  width: 9px;
  height: 9px;
  background-color: rgba(0, 0, 0, 0.2);
  border: 1px solid rgba(255, 255, 255, 0.7);
  box-sizing: border-box;
  outline: 1px solid transparent; }

.ReactCrop .ord-nw {
  top: 0;
  left: 0;
  margin-top: -5px;
  margin-left: -5px;
  cursor: nw-resize; }

.ReactCrop .ord-n {
  top: 0;
  left: 50%;
  margin-top: -5px;
  margin-left: -5px;
  cursor: n-resize; }

.ReactCrop .ord-ne {
  top: 0;
  right: 0;
  margin-top: -5px;
  margin-right: -5px;
  cursor: ne-resize; }

.ReactCrop .ord-e {
  top: 50%;
  right: 0;
  margin-top: -5px;
  margin-right: -5px;
  cursor: e-resize; }

.ReactCrop .ord-se {
  bottom: 0;
  right: 0;
  margin-bottom: -5px;
  margin-right: -5px;
  cursor: se-resize; }

.ReactCrop .ord-s {
  bottom: 0;
  left: 50%;
  margin-bottom: -5px;
  margin-left: -5px;
  cursor: s-resize; }

.ReactCrop .ord-sw {
  bottom: 0;
  left: 0;
  margin-bottom: -5px;
  margin-left: -5px;
  cursor: sw-resize; }

.ReactCrop .ord-w {
  top: 50%;
  left: 0;
  margin-top: -5px;
  margin-left: -5px;
  cursor: w-resize; }

.ReactCrop__disabled .ReactCrop__drag-handle {
  cursor: inherit; }

.ReactCrop__drag-bar {
  position: absolute; }

.ReactCrop__drag-bar.ord-n {
  top: 0;
  left: 0;
  width: 100%;
  height: 6px;
  margin-top: -4px; }

.ReactCrop__drag-bar.ord-e {
  right: 0;
  top: 0;
  width: 6px;
  height: 100%;
  margin-right: -4px; }

.ReactCrop__drag-bar.ord-s {
  bottom: 0;
  left: 0;
  width: 100%;
  height: 6px;
  margin-bottom: -4px; }

.ReactCrop__drag-bar.ord-w {
  top: 0;
  left: 0;
  width: 6px;
  height: 100%;
  margin-left: -4px; }

.ReactCrop--new-crop .ReactCrop__drag-bar,
.ReactCrop--new-crop .ReactCrop__drag-handle,
.ReactCrop--fixed-aspect .ReactCrop__drag-bar {
  display: none; }

.ReactCrop--fixed-aspect .ReactCrop__drag-handle.ord-n,
.ReactCrop--fixed-aspect .ReactCrop__drag-handle.ord-e,
.ReactCrop--fixed-aspect .ReactCrop__drag-handle.ord-s,
.ReactCrop--fixed-aspect .ReactCrop__drag-handle.ord-w {
  display: none; }

@media (max-width: 768px) {
  .ReactCrop__drag-handle {
    width: 17px;
    height: 17px; }
  .ReactCrop .ord-nw {
    margin-top: -9px;
    margin-left: -9px; }
  .ReactCrop .ord-n {
    margin-top: -9px;
    margin-left: -9px; }
  .ReactCrop .ord-ne {
    margin-top: -9px;
    margin-right: -9px; }
  .ReactCrop .ord-e {
    margin-top: -9px;
    margin-right: -9px; }
  .ReactCrop .ord-se {
    margin-bottom: -9px;
    margin-right: -9px; }
  .ReactCrop .ord-s {
    margin-bottom: -9px;
    margin-left: -9px; }
  .ReactCrop .ord-sw {
    margin-bottom: -9px;
    margin-left: -9px; }
  .ReactCrop .ord-w {
    margin-top: -9px;
    margin-left: -9px; }
  .ReactCrop__drag-bar.ord-n {
    height: 14px;
    margin-top: -12px; }
  .ReactCrop__drag-bar.ord-e {
    width: 14px;
    margin-right: -12px; }
  .ReactCrop__drag-bar.ord-s {
    height: 14px;
    margin-bottom: -12px; }
  .ReactCrop__drag-bar.ord-w {
    width: 14px;
    margin-left: -12px; } }

@-webkit-keyframes loading-first-circ {
    0% {
        -webkit-transform: scale3d(.5, .5, 1) translateZ(1px);
                transform: scale3d(.5, .5, 1) translateZ(1px);
        opacity: 1;
    }
    50% {
        -webkit-transform: scale3d(.25, .25, 1) translateZ(1px);
                transform: scale3d(.25, .25, 1) translateZ(1px);
        opacity: 0;
    }
    100% {
        -webkit-transform: scale3d(.25, .25, 1) translateZ(1px);
                transform: scale3d(.25, .25, 1) translateZ(1px);
        opacity: 0;
    }
}

@keyframes loading-first-circ {
    0% {
        -webkit-transform: scale3d(.5, .5, 1) translateZ(1px);
                transform: scale3d(.5, .5, 1) translateZ(1px);
        opacity: 1;
    }
    50% {
        -webkit-transform: scale3d(.25, .25, 1) translateZ(1px);
                transform: scale3d(.25, .25, 1) translateZ(1px);
        opacity: 0;
    }
    100% {
        -webkit-transform: scale3d(.25, .25, 1) translateZ(1px);
                transform: scale3d(.25, .25, 1) translateZ(1px);
        opacity: 0;
    }
}

@-webkit-keyframes loading-second-circ {
    0% {
        -webkit-transform: scale3d(1, 1, 1) translateZ(1px);
                transform: scale3d(1, 1, 1) translateZ(1px);
        opacity: 0;
    }
    25% {
        -webkit-transform: scale3d(1, 1, 1) translateZ(1px);
                transform: scale3d(1, 1, 1) translateZ(1px);
        opacity: 0;
    }
    75% {
        -webkit-transform: scale3d(.5, .5, 1) translateZ(1px);
                transform: scale3d(.5, .5, 1) translateZ(1px);
        opacity: 1;
    }
    100% {
        -webkit-transform: scale3d(.5, .5, 1) translateZ(1px);
                transform: scale3d(.5, .5, 1) translateZ(1px);
        opacity: 1;
    }
}

@keyframes loading-second-circ {
    0% {
        -webkit-transform: scale3d(1, 1, 1) translateZ(1px);
                transform: scale3d(1, 1, 1) translateZ(1px);
        opacity: 0;
    }
    25% {
        -webkit-transform: scale3d(1, 1, 1) translateZ(1px);
                transform: scale3d(1, 1, 1) translateZ(1px);
        opacity: 0;
    }
    75% {
        -webkit-transform: scale3d(.5, .5, 1) translateZ(1px);
                transform: scale3d(.5, .5, 1) translateZ(1px);
        opacity: 1;
    }
    100% {
        -webkit-transform: scale3d(.5, .5, 1) translateZ(1px);
                transform: scale3d(.5, .5, 1) translateZ(1px);
        opacity: 1;
    }
}

.nst-loading {
    width: 48px;
    height: 48px;
    position: relative;
}

.nst-loading.sm {
    width: 32px;
    height: 32px;
}

.nst-loading.xsm {
    width: 16px;
    height: 16px;
}

.nst-loading.absolute {
    left: 50%;
    margin-left: -24px;
    margin-top: -24px;
    position: absolute;
    top: 50%;
}

.nst-loading.white::before,
.nst-loading.white::after {
    background-color: #fff;
}

.nst-loading::after,
.nst-loading::before {
    content: ' ';
    left: 0;
    top: 0;
    position: absolute;
    width: 100%;
    height: 100%;
    border-radius: 24px;
    background-color: #14D769;
    -webkit-transform-origin: center;
            transform-origin: center;
    -webkit-perspective: 1000;
            perspective: 1000;
    -webkit-backface-visibility: hidden;
            backface-visibility: hidden;
    will-change: transform;
    -webkit-transform: translateZ(0);
            transform: translateZ(0);
}

.nst-loading::after {
    -webkit-animation: loading-first-circ 1s linear 0s infinite normal running;
            animation: loading-first-circ 1s linear 0s infinite normal running;
}

.nst-loading::before {
    -webkit-animation: loading-second-circ 1s linear 0s infinite normal running;
            animation: loading-second-circ 1s linear 0s infinite normal running;
}

.selectLevelContainer {
  display: flex;
  width: 100%;
  flex-direction: column; }

.selectLevelSlider {
  height: 48px;
  position: relative;
  display: flex;
  width: 100%;
  align-self: center;
  align-items: center;
  margin-top: 2px; }
  .selectLevelSlider li {
    height: 24px;
    display: flex;
    align-items: center;
    max-width: 96px; }
  .selectLevelSlider .levelIcon {
    width: 40px;
    z-index: 3;
    display: flex;
    justify-content: center;
    align-items: center;
    opacity: .16;
    -webkit-transition: fill .3s ease-in 150ms;
    transition: fill .3s ease-in 150ms;
    -webkit-transition-delay: 200ms;
            transition-delay: 200ms; }
    .selectLevelSlider .levelIcon.active svg,
    .selectLevelSlider .levelIcon.active svg path {
      fill: #14D769; }
  .selectLevelSlider li:first-of-type .levelIcon {
    -webkit-transition-delay: 0ms;
            transition-delay: 0ms; }
  .selectLevelSlider li:nth-child(2) .levelIcon {
    -webkit-transition-delay: 50ms;
            transition-delay: 50ms; }
  .selectLevelSlider li:nth-child(3) .levelIcon {
    -webkit-transition-delay: 100ms;
            transition-delay: 100ms; }
  .selectLevelSlider li:nth-child(4) .levelIcon {
    -webkit-transition-delay: 150ms;
            transition-delay: 150ms; }
  .selectLevelSlider li:last-of-type {
    -webkit-transition-delay: 0ms;
            transition-delay: 0ms; }
    .selectLevelSlider li:last-of-type hr.margin:last-child {
      opacity: 0; }
  .selectLevelSlider hr.margin {
    display: flex;
    flex: 1 1;
    height: 2px;
    margin: 0;
    background-color: rgba(50, 61, 71, 0.08);
    border: none; }
  .selectLevelSlider .active-border {
    position: absolute;
    z-index: 2;
    height: 100%;
    border: 2px solid #14D769;
    left: 0;
    top: 0;
    border-radius: 48px;
    -webkit-transition: width .3s ease-in-out;
    transition: width .3s ease-in-out;
    background-color: #fff;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.08); }
  .selectLevelSlider.l0 li:first-of-type .levelIcon {
    opacity: 1; }
  .selectLevelSlider.l1 li:first-of-type .levelIcon,
  .selectLevelSlider.l1 li:nth-child(2) .levelIcon {
    opacity: 1; }
  .selectLevelSlider.l2 li:first-of-type .levelIcon,
  .selectLevelSlider.l2 li:nth-child(2) .levelIcon,
  .selectLevelSlider.l2 li:nth-child(3) .levelIcon {
    opacity: 1; }
  .selectLevelSlider.l3 li:first-of-type .levelIcon,
  .selectLevelSlider.l3 li:nth-child(2) .levelIcon,
  .selectLevelSlider.l3 li:nth-child(3) .levelIcon,
  .selectLevelSlider.l3 li:nth-child(4) .levelIcon {
    opacity: 1; }
  .selectLevelSlider.l4 li:first-of-type .levelIcon,
  .selectLevelSlider.l4 li:nth-child(2) .levelIcon,
  .selectLevelSlider.l4 li:nth-child(3) .levelIcon,
  .selectLevelSlider.l4 li:nth-child(4) .levelIcon,
  .selectLevelSlider.l4 li:nth-child(5) .levelIcon {
    opacity: 1; }
  .selectLevelSlider .receive-arrow {
    width: 16px;
    height: 16px;
    background-color: #F3F3F4;
    -webkit-transform: rotateZ(45deg) translateX(-14px);
            transform: rotateZ(45deg) translateX(-14px);
    position: absolute;
    bottom: -34px;
    z-index: 1;
    -webkit-transition: left .3s ease-in-out;
    transition: left .3s ease-in-out;
    border-left: 1px solid rgba(50, 61, 71, 0.08);
    border-top: 1px solid rgba(50, 61, 71, 0.08); }

.selectLevelDetail {
  margin-top: 16px;
  width: 100%;
  border-radius: 4px;
  border: 1px solid rgba(50, 61, 71, 0.08);
  background-color: #F3F3F4;
  margin-bottom: 30px;
  padding: 0 12px; }
  .selectLevelDetail h4 {
    font-size: 14px;
    text-align: center;
    line-height: 20px;
    margin: 12px 0; }
  .selectLevelDetail .searchContainer {
    flex-direction: column;
    display: flex;
    width: 100%;
    margin-bottom: 12px; }
    .selectLevelDetail .searchContainer .ant-row-flex {
      flex-wrap: nowrap; }
  .selectLevelDetail label {
    font-size: 14px;
    width: 100%;
    color: #323d47;
    display: flex; }
  .selectLevelDetail p {
    background-color: rgba(50, 61, 71, 0.08);
    font-size: 13px;
    border-radius: 4px;
    padding: 4px 6px;
    margin: 6px 0 0 0;
    color: #323d47; }
  .selectLevelDetail > div {
    margin: 24px 0 0 0; }

.rdw-option-wrapper {
  border: 1px solid #f1f1f1;
  padding: 5px;
  min-width: 25px;
  height: 20px;
  border-radius: 2px;
  margin: 0 4px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  background: white;
  text-transform: capitalize; }

.rdw-option-wrapper:hover {
  box-shadow: 1px 1px 0px #bfbdbd; }

.rdw-option-wrapper:active {
  box-shadow: 1px 1px 0px #bfbdbd inset; }

.rdw-option-active {
  box-shadow: 1px 1px 0px #bfbdbd inset; }

.rdw-option-disabled {
  opacity: 0.3;
  cursor: default; }

.rdw-dropdown-wrapper {
  height: 30px;
  background: white;
  cursor: pointer;
  border: 1px solid #f1f1f1;
  border-radius: 2px;
  margin: 0 3px;
  text-transform: capitalize;
  background: white; }

.rdw-dropdown-wrapper:focus {
  outline: none; }

.rdw-dropdown-wrapper:hover {
  box-shadow: 1px 1px 0px #bfbdbd;
  background-color: #ffffff; }

.rdw-dropdown-wrapper:active {
  box-shadow: 1px 1px 0px #bfbdbd inset; }

.rdw-dropdown-carettoopen {
  height: 0px;
  width: 0px;
  position: absolute;
  top: 35%;
  right: 10%;
  border-top: 6px solid black;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent; }

.rdw-dropdown-carettoclose {
  height: 0px;
  width: 0px;
  position: absolute;
  top: 35%;
  right: 10%;
  border-bottom: 6px solid black;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent; }

.rdw-dropdown-selectedtext {
  display: flex;
  position: relative;
  height: 100%;
  align-items: center;
  padding: 0 5px; }

.rdw-dropdown-optionwrapper {
  z-index: 100;
  position: relative;
  border: 1px solid #f1f1f1;
  width: 98%;
  background: white;
  border-radius: 2px;
  margin: 0;
  padding: 0;
  max-height: 250px;
  overflow-y: scroll; }

.rdw-dropdown-optionwrapper:hover {
  box-shadow: 1px 1px 0px #bfbdbd;
  background-color: #ffffff; }

.rdw-dropdownoption-default {
  min-height: 25px;
  display: flex;
  align-items: center;
  padding: 0 5px; }

.rdw-dropdownoption-highlighted {
  background: #f1f1f1; }

.rdw-dropdownoption-active {
  background: #f5f5f5; }

.rdw-dropdownoption-disabled {
  opacity: 0.3;
  cursor: default; }

.rdw-inline-wrapper {
  display: flex;
  align-items: center;
  margin-bottom: 6px; }

.rdw-inline-dropdown {
  width: 50px; }

.rdw-inline-dropdownoption {
  height: 40px;
  display: flex;
  justify-content: center; }

.rdw-block-wrapper {
  display: flex;
  align-items: center;
  margin-bottom: 6px; }

.rdw-block-dropdown {
  width: 110px; }

.rdw-fontsize-wrapper {
  display: flex;
  align-items: center;
  margin-bottom: 6px; }

.rdw-fontsize-dropdown {
  min-width: 40px; }

.rdw-fontsize-option {
  display: flex;
  justify-content: center; }

.rdw-fontfamily-wrapper {
  display: flex;
  align-items: center;
  margin-bottom: 6px; }

.rdw-fontfamily-dropdown {
  width: 115px; }

.rdw-fontfamily-placeholder {
  white-space: nowrap;
  max-width: 90px;
  overflow: hidden;
  text-overflow: ellipsis; }

.rdw-fontfamily-optionwrapper {
  width: 140px; }

.rdw-list-wrapper {
  display: flex;
  align-items: center;
  margin-bottom: 6px; }

.rdw-list-dropdown {
  width: 50px;
  z-index: 90; }

.rdw-list-dropdownOption {
  height: 40px;
  display: flex;
  justify-content: center; }

.rdw-text-align-wrapper {
  display: flex;
  align-items: center;
  margin-bottom: 6px; }

.rdw-text-align-dropdown {
  width: 50px;
  z-index: 90; }

.rdw-text-align-dropdownOption {
  height: 40px;
  display: flex;
  justify-content: center; }

.rdw-right-aligned-block {
  text-align: right; }

.rdw-left-aligned-block {
  text-align: left !important; }

.rdw-center-aligned-block {
  text-align: center !important; }

.rdw-justify-aligned-block {
  text-align: justify !important; }

.rdw-right-aligned-block > div {
  display: inline-block; }

.rdw-left-aligned-block > div {
  display: inline-block; }

.rdw-center-aligned-block > div {
  display: inline-block; }

.rdw-justify-aligned-block > div {
  display: inline-block; }

.rdw-colorpicker-wrapper {
  display: flex;
  align-items: center;
  margin-bottom: 6px;
  position: relative; }

.rdw-colorpicker-modal {
  position: absolute;
  top: 35px;
  left: 5px;
  display: flex;
  flex-direction: column;
  width: 175px;
  height: 175px;
  border: 1px solid #f1f1f1;
  padding: 15px;
  border-radius: 2px;
  z-index: 100;
  background: white;
  box-shadow: 3px 3px 5px #bfbdbd; }

.rdw-colorpicker-modal-header {
  display: flex;
  padding-bottom: 5px; }

.rdw-colorpicker-modal-style-label {
  font-size: 15px;
  width: 50%;
  text-align: center;
  cursor: pointer;
  padding: 0 10px 5px; }

.rdw-colorpicker-modal-style-label-active {
  border-bottom: 2px solid #0a66b7; }

.rdw-colorpicker-modal-options {
  margin: 5px auto;
  display: flex;
  width: 100%;
  height: 100%;
  flex-wrap: wrap;
  overflow: scroll; }

.rdw-colorpicker-cube {
  width: 22px;
  height: 22px;
  border: 1px solid #f1f1f1; }

.rdw-colorpicker-option {
  margin: 3px;
  padding: 0;
  min-height: 20px;
  border: none;
  width: 22px;
  height: 22px;
  min-width: 22px;
  box-shadow: 1px 2px 1px #bfbdbd inset; }

.rdw-colorpicker-option:hover {
  box-shadow: 1px 2px 1px #bfbdbd; }

.rdw-colorpicker-option:active {
  box-shadow: -1px -2px 1px #bfbdbd; }

.rdw-colorpicker-option-active {
  box-shadow: 0px 0px 2px 2px #bfbdbd; }

.rdw-link-wrapper {
  display: flex;
  align-items: center;
  margin-bottom: 6px;
  position: relative; }

.rdw-link-dropdown {
  width: 50px; }

.rdw-link-dropdownOption {
  height: 40px;
  display: flex;
  justify-content: center; }

.rdw-link-dropdownPlaceholder {
  margin-left: 8px; }

.rdw-link-modal {
  position: absolute;
  top: 35px;
  left: 5px;
  display: flex;
  flex-direction: column;
  width: 235px;
  height: 205px;
  border: 1px solid #f1f1f1;
  padding: 15px;
  border-radius: 2px;
  z-index: 100;
  background: white;
  box-shadow: 3px 3px 5px #bfbdbd; }

.rdw-link-modal-label {
  font-size: 15px; }

.rdw-link-modal-input {
  margin-top: 5px;
  border-radius: 2px;
  border: 1px solid #f1f1f1;
  height: 25px;
  margin-bottom: 15px;
  padding: 0 5px; }

.rdw-link-modal-input:focus {
  outline: none; }

.rdw-link-modal-buttonsection {
  margin: 0 auto; }

.rdw-link-modal-target-option {
  margin-bottom: 20px; }

.rdw-link-modal-target-option > span {
  margin-left: 5px; }

.rdw-link-modal-btn {
  margin-left: 10px;
  width: 75px;
  height: 30px;
  border: 1px solid #f1f1f1;
  border-radius: 2px;
  cursor: pointer;
  background: white;
  text-transform: capitalize; }

.rdw-link-modal-btn:hover {
  box-shadow: 1px 1px 0px #bfbdbd; }

.rdw-link-modal-btn:active {
  box-shadow: 1px 1px 0px #bfbdbd inset; }

.rdw-link-modal-btn:focus {
  outline: none !important; }

.rdw-link-modal-btn:disabled {
  background: #ece9e9; }

.rdw-link-dropdownoption {
  height: 40px;
  display: flex;
  justify-content: center; }

.rdw-history-dropdown {
  width: 50px; }

.rdw-embedded-wrapper {
  display: flex;
  align-items: center;
  margin-bottom: 6px;
  position: relative; }

.rdw-embedded-modal {
  position: absolute;
  top: 35px;
  left: 5px;
  display: flex;
  flex-direction: column;
  width: 235px;
  height: 180px;
  border: 1px solid #f1f1f1;
  padding: 15px;
  border-radius: 2px;
  z-index: 100;
  background: white;
  justify-content: space-between;
  box-shadow: 3px 3px 5px #bfbdbd; }

.rdw-embedded-modal-header {
  font-size: 15px;
  display: flex; }

.rdw-embedded-modal-header-option {
  width: 50%;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column; }

.rdw-embedded-modal-header-label {
  width: 95px;
  border: 1px solid #f1f1f1;
  margin-top: 5px;
  background: #6eb8d4;
  border-bottom: 2px solid #0a66b7; }

.rdw-embedded-modal-link-section {
  display: flex;
  flex-direction: column; }

.rdw-embedded-modal-link-input {
  width: 88%;
  height: 35px;
  margin: 10px 0;
  border: 1px solid #f1f1f1;
  border-radius: 2px;
  font-size: 15px;
  padding: 0 5px; }

.rdw-embedded-modal-link-input-wrapper {
  display: flex;
  align-items: center; }

.rdw-embedded-modal-link-input:focus {
  outline: none; }

.rdw-embedded-modal-btn-section {
  display: flex;
  justify-content: center; }

.rdw-embedded-modal-btn {
  margin: 0 3px;
  width: 75px;
  height: 30px;
  border: 1px solid #f1f1f1;
  border-radius: 2px;
  cursor: pointer;
  background: white;
  text-transform: capitalize; }

.rdw-embedded-modal-btn:hover {
  box-shadow: 1px 1px 0px #bfbdbd; }

.rdw-embedded-modal-btn:active {
  box-shadow: 1px 1px 0px #bfbdbd inset; }

.rdw-embedded-modal-btn:focus {
  outline: none !important; }

.rdw-embedded-modal-btn:disabled {
  background: #ece9e9; }

.rdw-embedded-modal-size {
  align-items: center;
  display: flex;
  margin: 8px 0;
  justify-content: space-between; }

.rdw-embedded-modal-size-input {
  width: 80%;
  height: 20px;
  border: 1px solid #f1f1f1;
  border-radius: 2px;
  font-size: 12px; }

.rdw-embedded-modal-size-input:focus {
  outline: none; }

.rdw-emoji-wrapper {
  display: flex;
  align-items: center;
  margin-bottom: 6px;
  position: relative; }

.rdw-emoji-modal {
  overflow: auto;
  position: absolute;
  top: 35px;
  left: 5px;
  display: flex;
  flex-wrap: wrap;
  width: 235px;
  height: 180px;
  border: 1px solid #f1f1f1;
  padding: 15px;
  border-radius: 2px;
  z-index: 100;
  background: white;
  box-shadow: 3px 3px 5px #bfbdbd; }

.rdw-emoji-icon {
  margin: 2.5px;
  height: 24px;
  width: 24px;
  cursor: pointer;
  font-size: 22px;
  display: flex;
  justify-content: center;
  align-items: center; }

.rdw-spinner {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%; }

.rdw-spinner > div {
  width: 12px;
  height: 12px;
  background-color: #333;
  border-radius: 100%;
  display: inline-block;
  -webkit-animation: sk-bouncedelay 1.4s infinite ease-in-out both;
  animation: sk-bouncedelay 1.4s infinite ease-in-out both; }

.rdw-spinner .rdw-bounce1 {
  -webkit-animation-delay: -0.32s;
  animation-delay: -0.32s; }

.rdw-spinner .rdw-bounce2 {
  -webkit-animation-delay: -0.16s;
  animation-delay: -0.16s; }

@-webkit-keyframes sk-bouncedelay {
  0%,
  80%,
  100% {
    -webkit-transform: scale(0); }
  40% {
    -webkit-transform: scale(1); } }

@keyframes sk-bouncedelay {
  0%,
  80%,
  100% {
    -webkit-transform: scale(0);
    transform: scale(0); }
  40% {
    -webkit-transform: scale(1);
    transform: scale(1); } }

.rdw-image-wrapper {
  display: flex;
  align-items: center;
  margin-bottom: 6px;
  position: relative; }

.rdw-image-modal {
  position: absolute;
  top: 35px;
  left: 5px;
  display: flex;
  flex-direction: column;
  width: 235px;
  border: 1px solid #f1f1f1;
  padding: 15px;
  border-radius: 2px;
  z-index: 100;
  background: white;
  box-shadow: 3px 3px 5px #bfbdbd; }

.rdw-image-modal-header {
  font-size: 15px;
  margin: 10px 0;
  display: flex; }

.rdw-image-modal-header-option {
  width: 50%;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column; }

.rdw-image-modal-header-label {
  width: 80px;
  background: #f1f1f1;
  border: 1px solid #f1f1f1;
  margin-top: 5px; }

.rdw-image-modal-header-label-highlighted {
  background: #6eb8d4;
  border-bottom: 2px solid #0a66b7; }

.rdw-image-modal-upload-option {
  width: 100%;
  color: gray;
  cursor: pointer;
  display: flex;
  border: none;
  font-size: 15px;
  align-items: center;
  justify-content: center;
  background-color: #f1f1f1;
  outline: 2px dashed gray;
  outline-offset: -10px;
  margin: 10px 0;
  padding: 9px 0; }

.rdw-image-modal-upload-option-highlighted {
  outline: 2px dashed #0a66b7; }

.rdw-image-modal-upload-option-label {
  cursor: pointer;
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 15px; }

.rdw-image-modal-upload-option-label span {
  padding: 0 20px; }

.rdw-image-modal-upload-option-image-preview {
  max-width: 100%;
  max-height: 200px; }

.rdw-image-modal-upload-option-input {
  width: 0.1px;
  height: 0.1px;
  opacity: 0;
  overflow: hidden;
  position: absolute;
  z-index: -1; }

.rdw-image-modal-url-section {
  display: flex;
  align-items: center; }

.rdw-image-modal-url-input {
  width: 90%;
  height: 35px;
  margin: 15px 0 12px;
  border: 1px solid #f1f1f1;
  border-radius: 2px;
  font-size: 15px;
  padding: 0 5px; }

.rdw-image-modal-btn-section {
  margin: 10px auto 0; }

.rdw-image-modal-url-input:focus {
  outline: none; }

.rdw-image-modal-btn {
  margin: 0 5px;
  width: 75px;
  height: 30px;
  border: 1px solid #f1f1f1;
  border-radius: 2px;
  cursor: pointer;
  background: white;
  text-transform: capitalize; }

.rdw-image-modal-btn:hover {
  box-shadow: 1px 1px 0px #bfbdbd; }

.rdw-image-modal-btn:active {
  box-shadow: 1px 1px 0px #bfbdbd inset; }

.rdw-image-modal-btn:focus {
  outline: none !important; }

.rdw-image-modal-btn:disabled {
  background: #ece9e9; }

.rdw-image-modal-spinner {
  position: absolute;
  top: -3px;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0.5; }

.rdw-image-modal-alt-input {
  width: 70%;
  height: 20px;
  border: 1px solid #f1f1f1;
  border-radius: 2px;
  font-size: 12px;
  margin-left: 5px; }

.rdw-image-modal-alt-input:focus {
  outline: none; }

.rdw-image-modal-alt-lbl {
  font-size: 12px; }

.rdw-image-modal-size {
  align-items: center;
  display: flex;
  margin: 8px 0;
  justify-content: space-between; }

.rdw-image-modal-size-input {
  width: 40%;
  height: 20px;
  border: 1px solid #f1f1f1;
  border-radius: 2px;
  font-size: 12px; }

.rdw-image-modal-size-input:focus {
  outline: none; }

.rdw-image-mandatory-sign {
  color: red;
  margin-left: 3px;
  margin-right: 3px; }

.rdw-remove-wrapper {
  display: flex;
  align-items: center;
  margin-bottom: 6px;
  position: relative; }

.rdw-history-wrapper {
  display: flex;
  align-items: center;
  margin-bottom: 6px; }

.rdw-history-dropdownoption {
  height: 40px;
  display: flex;
  justify-content: center; }

.rdw-history-dropdown {
  width: 50px; }

.rdw-link-decorator-wrapper {
  position: relative; }

.rdw-link-decorator-icon {
  position: absolute;
  left: 40%;
  top: 0;
  cursor: pointer;
  background-color: white; }

.rdw-mention-link {
  text-decoration: none;
  color: #1236ff;
  background-color: #f0fbff;
  padding: 1px 2px;
  border-radius: 2px; }

.rdw-suggestion-wrapper {
  position: relative; }

.rdw-suggestion-dropdown {
  position: absolute;
  display: flex;
  flex-direction: column;
  border: 1px solid #f1f1f1;
  min-width: 100px;
  max-height: 150px;
  overflow: auto;
  background: white;
  z-index: 100; }

.rdw-suggestion-option {
  padding: 7px 5px;
  border-bottom: 1px solid #f1f1f1; }

.rdw-suggestion-option-active {
  background-color: #f1f1f1; }

.rdw-hashtag-link {
  text-decoration: none;
  color: #1236ff;
  background-color: #f0fbff;
  padding: 1px 2px;
  border-radius: 2px; }

.rdw-image-alignment-options-popup {
  position: absolute;
  background: white;
  display: flex;
  padding: 5px 2px;
  border-radius: 2px;
  border: 1px solid #f1f1f1;
  width: 105px;
  cursor: pointer;
  z-index: 100; }

.rdw-alignment-option-left {
  justify-content: flex-start; }

.rdw-image-alignment-option {
  height: 15px;
  width: 15px;
  min-width: 15px; }

.rdw-image-alignment {
  position: relative; }

.rdw-image-imagewrapper {
  position: relative; }

.rdw-image-center {
  display: flex;
  justify-content: center; }

.rdw-image-left {
  display: flex; }

.rdw-image-right {
  display: flex;
  justify-content: flex-end; }

.rdw-image-alignment-options-popup-right {
  right: 0; }

.rdw-editor-main {
  height: 100%;
  overflow: auto;
  box-sizing: border-box; }

.rdw-editor-toolbar {
  padding: 6px 5px 0;
  border-radius: 2px;
  border: 1px solid #f1f1f1;
  display: flex;
  justify-content: flex-start;
  background: white;
  flex-wrap: wrap;
  font-size: 15px;
  margin-bottom: 5px;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none; }

.public-DraftStyleDefault-block {
  margin: 1em 0; }

.rdw-editor-wrapper:focus {
  outline: none; }

.rdw-editor-wrapper {
  box-sizing: content-box; }

.rdw-editor-main blockquote {
  border-left: 5px solid #f1f1f1;
  padding-left: 5px; }

.rdw-editor-main pre {
  background: #f1f1f1;
  border-radius: 3px;
  padding: 1px 10px; }


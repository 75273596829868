.nst-ico {
  color: transparent;
  background-position: center center;
  background-size: cover;
  transform-origin: center;
  transition: none;
  box-sizing: border-box;
  background-repeat: no-repeat;
  &:hover,
  &:focus {
    color: transparent;
    background-color: transparent;
    box-sizing: border-box;
    border: none;
    background-position: center center;
    background-size: cover;
  }
}

.ic_absolout_place_solid_24,
.ic_absolout_place_solid_24:hover,
.ic_absolout_place_solid_24:focus {
  background-image: url('/images/ic_absolout_place_solid_24.png');
}

.ic_access_sensewire_24,
.ic_access_sensewire_24:hover,
.ic_access_sensewire_24:focus {
  background-image: url('/images/ic_access_sensewire_24.png');
}

.ic_access_solid_24,
.ic_access_solid_24:hover,
.ic_access_solid_24:focus {
  background-image: url('/images/ic_access_solid_24.png');
}

.ic_account_sensewire_24,
.ic_account_sensewire_24:hover,
.ic_account_sensewire_24:focus {
  background-image: url('/images/ic_account_sensewire_24.png');
}

.ic_account_solid_24,
.ic_account_solid_24:hover,
.ic_account_solid_24:focus {
  background-image: url('/images/ic_account_solid_24.png');
}

.ic_admins_log_solid_24,
.ic_admins_log_solid_24:hover,
.ic_admins_log_solid_24:focus {
  background-image: url('/images/ic_admins_log_solid_24.png');
}

.ic_bell_solid_24,
.ic_bell_solid_24:hover,
.ic_bell_solid_24:focus {
  background-image: url('/images/ic_bell_solid_24.png');
}

.ic_bin_force_16,
.ic_bin_force_16:hover,
.ic_bin_force_16:focus {
  background-image: url('/images/ic_bin_force_16.png');
}

.ic_brick_wall_solid_16,
.ic_brick_wall_solid_16:hover,
.ic_brick_wall_solid_16:focus {
  background-image: url('/images/ic_brick_wall_solid_16.png');
}

.ic_brick_wall_solid_24,
.ic_brick_wall_solid_24:hover,
.ic_brick_wall_solid_24:focus {
  background-image: url('/images/ic_brick_wall_solid_24.png');
}

.ic_check_senesewire_24,
.ic_check_senesewire_24:hover,
.ic_check_senesewire_24:focus {
  background-image: url('/images/ic_check_senesewire_24.png');
}

.ic_crown_shine_24,
.ic_crown_shine_24:hover,
.ic_crown_shine_24:focus {
  background-image: url('/images/ic_crown_shine_24.png');
}

.ic_dashboard_sensewire_24,
.ic_dashboard_sensewire_24:hover,
.ic_dashboard_sensewire_24:focus {
  background-image: url('/images/ic_dashboard_sensewire_24.png');
}

.ic_dashboard_solid_24,
.ic_dashboard_solid_24:hover,
.ic_dashboard_solid_24:focus {
  background-image: url('/images/ic_dashboard_solid_24.png');
}

.ic_earth_solid_16,
.ic_earth_solid_16:hover,
.ic_earth_solid_16:focus {
  background-image: url('/images/ic_earth_solid_16.png');
}

.ic_earth_solid_24,
.ic_earth_solid_24:hover,
.ic_earth_solid_24:focus {
  background-image: url('/images/ic_earth_solid_24.png');
}

.ic_gear_sensewire_24,
.ic_gear_sensewire_24:hover,
.ic_gear_sensewire_24:focus {
  background-image: url('/images/ic_gear_sensewire_24.png');
}

.ic_gear_solid_-1,
.ic_gear_solid_-1:hover,
.ic_gear_solid_-1:focus {
  background-image: url('/images/ic_gear_solid_-1.png');
}

.ic_gear_solid_24,
.ic_gear_solid_24:hover,
.ic_gear_solid_24:focus {
  background-image: url('/images/ic_gear_solid_24.png');
}

.ic_grandplace_solid_-1,
.ic_grandplace_solid_-1:hover,
.ic_grandplace_solid_-1:focus {
  background-image: url('/images/ic_grandplace_solid_-1.png');
}

.ic_grandplace_solid_16,
.ic_grandplace_solid_16:hover,
.ic_grandplace_solid_16:focus {
  background-image: url('/images/ic_grandplace_solid_16.png');
}

.ic_heavy_arrow_down_solid_16,
.ic_heavy_arrow_down_solid_16:hover,
.ic_heavy_arrow_down_solid_16:focus {
  background-image: url('/images/ic_heavy_arrow_down_solid_16.png');
}

.ic_heavy_arrow_down_solid_24,
.ic_heavy_arrow_down_solid_24:hover,
.ic_heavy_arrow_down_solid_24:focus {
  background-image: url('/images/ic_heavy_arrow_down_solid_24.png');
}

.ic_manager-and-member_solid_24,
.ic_manager-and-member_solid_24:hover,
.ic_manager-and-member_solid_24:focus {
  background-image: url('/images/ic_manager-and-member_solid_24.png');
}

.ic_lock_solid_24,
.ic_lock_solid_24:hover,
.ic_lock_solid_24:focus {
  background-image: url('/images/ic_lock_solid_24.png');
}

.ic_logout_solid_24,
.ic_logout_solid_24:hover,
.ic_logout_solid_24:focus {
  background-image: url('/images/ic_logout_solid_24.png');
}

.ic_magnify_solid_24,
.ic_magnify_solid_24:hover,
.ic_magnify_solid_24:focus {
  background-image: url('/images/ic_magnify_solid_24.png');
}

.ic_manager_and_member_solid_16,
.ic_manager_and_member_solid_16:hover,
.ic_manager_and_member_solid_16:focus {
  background-image: url('/images/ic_manager_and_member_solid_16.png');
}

.ic_manager_solid_16,
.ic_manager_solid_16:hover,
.ic_manager_solid_16:focus {
  background-image: url('/images/ic_manager_solid_16.png');
}

.ic_manager_solid_24,
.ic_manager_solid_24:hover,
.ic_manager_solid_24:focus {
  background-image: url('/images/ic_manager_solid_24.png');
}

.ic_manager-and-member_solid_24,
.ic_manager-and-member_solid_24:hover,
.ic_manager-and-member_solid_24:focus {
  background-image: url('/images/ic_manager-and-member_solid_24.png');
}

.ic_member_solid_24,
.ic_member_solid_24:hover,
.ic_member_solid_24:focus {
  background-image: url('/images/ic_member_solid_24.png');
}

.ic_more_solid_16,
.ic_more_solid_16:hover,
.ic_more_solid_16:focus {
  background-image: url('/images/ic_more_solid_16.png');
}

.ic_more_solid_24,
.ic_more_solid_24:hover,
.ic_more_solid_24:focus {
  background-image: url('/images/ic_more_solid_24.png');
}

.ic_non_search_24,
.ic_non_search_24:hover,
.ic_non_search_24:focus {
  background-image: url('/images/ic_non_search_24.png');
}

.ic_open_message_solid_24,
.ic_open_message_solid_24:hover,
.ic_open_message_solid_24:focus {
  background-image: url('/images/ic_open_message_solid_24.png');
}

.ic_pencil_solid_16,
.ic_pencil_solid_16:hover,
.ic_pencil_solid_16:focus {
  background-image: url('/images/ic_pencil_solid_16.png');
}

.ic_places_sensewire_24,
.ic_places_sensewire_24:hover,
.ic_places_sensewire_24:focus {
  background-image: url('/images/ic_places_sensewire_24.png');
}

.ic_places_solid_24,
.ic_places_solid_24:hover,
.ic_places_solid_24:focus {
  background-image: url('/images/ic_places_solid_24.png');
}

.ic_plus_sensewire_16,
.ic_plus_sensewire_16:hover,
.ic_plus_sensewire_16:focus {
  background-image: url('/images/ic_plus_sensewire_16.png');
}

.ic_plus_solid_24,
.ic_plus_solid_24:hover,
.ic_plus_solid_24:focus {
  background-image: url('/images/ic_plus_solid_24.png');
}

.ic_relative_place_solid_24,
.ic_relative_place_solid_24:hover,
.ic_relative_place_solid_24:focus {
  background-image: url('/images/ic_relative_place_solid_24.png');
}

.ic_search_24,
.ic_search_24:hover,
.ic_search_24:focus {
  background-image: url('/images/ic_search_24.png');
}

.ic_search_solid_16,
.ic_search_solid_16:hover,
.ic_search_solid_16:focus {
  background-image: url('/images/ic_search_solid_16.png');
}

.ic_storage_sensewire_24,
.ic_storage_sensewire_24:hover,
.ic_storage_sensewire_24:focus {
  background-image: url('/images/ic_storage_sensewire_24.png');
}

.ic_storage_solid_24,
.ic_storage_solid_24:hover,
.ic_storage_solid_24:focus {
  background-image: url('/images/ic_storage_solid_24.png');
}

.ic_team_solid_16,
.ic_team_solid_16:hover,
.ic_team_solid_16:focus {
  background-image: url('/images/ic_team_solid_16.png');
}

.ic_team_solid_24,
.ic_team_solid_24:hover,
.ic_team_solid_24:focus {
  background-image: url('/images/ic_team_solid_24.png');
}

.ic_window_solid_16,
.ic_window_solid_16:hover,
.ic_window_solid_16:focus {
  background-image: url('/images/ic_window_solid_16.png');
}

.ic_window_solid_24,
.ic_window_solid_24:hover,
.ic_window_solid_24:focus {
  background-image: url('/images/ic_window_solid_24.png');
}

.ic_xcross_solid_24,
.ic_xcross_solid_24:hover,
.ic_xcross_solid_24:focus {
  background-image: url('/images/ic_xcross_solid_24.png');
}

.img_tickets,
.img_tickets:hover,
.img_tickets:focus {
  background-image: url('/images/img_tickets.png');
}
